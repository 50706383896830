import React from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import BanerContact from "components/BanerContact"

import { Content } from "page_components/payments"

const Payments = () => (
  <Layout isGrayMain>
    <Breadcrumbs title="Metody płatności" />
    <PageHeader title="Metody płatności" />
    <Content />
    <BanerContact />
  </Layout>
)

export const Head = () => (
  <Seo
    title="Metody Płatności - Bezpieczne zakupy"
    description="Dowiedz się więcej na temat dostępnych metod płatności w wallcraft.pl. Zapewniamy bezpieczne i szybkie zakupy. Sprawdź już dziś."
  />
)

export default Payments
